@import 'styles/theme';

$defaultSize: theme($spacings, 30);

.IconCta {
  width: $defaultSize;
  height: $defaultSize;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  &.disabled,
  &.loading {
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.35;
  }

  .icon,
  .spinner {
    width: 15px;
  }
}
