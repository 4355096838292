@import 'styles/theme';

.VideoControls {
  display: flex;
  align-items: center;
  transition: opacity 0.4s ease-in-out;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  .playpausebutton {
    + .muteunmutebutton {
      margin-left: 5px;
    }

    svg {
      width: 10px;
      max-height: 10px;
    }
  }

  .muteunmutebutton {
    + .progressbar {
      margin-left: 5px;
    }

    svg {
      width: 15px;
      max-height: 15px;
    }
  }

  .progressbar {
    flex: 1;
  }
}
