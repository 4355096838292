@use 'sass:math';
@import 'styles/theme';

.CartItem {
  transition: opacity 0.2s;
  opacity: 1;

  &.isMutating {
    pointer-events: none;
    opacity: 0.7;
  }

  // States
  &.isMini {
    .imageWrapper {
      width: math.div(155, 415) * 100%;

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-right',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );
    }
  }
}

.reference {
  color: theme($colors, 'gray');
}

.ratio {
  @include applyPercentRatios(math.div(188, 181));
}

.imageWrapper {
  position: relative;
  height: auto;
  flex-shrink: 0;

  @include setPropertyValue(
    (
      'values': $mobile,
      'propertyName': 'width',
      'gutters': 1,
      'gaps': 0,
      'columns': 6,
    )
  );

  @include setPropertyValue(
    (
      'values': $mobile,
      'propertyName': 'margin-right',
      'gutters': 0,
      'gaps': 1,
      'columns': 0,
    )
  );

  @include from('md') {
    @include setPropertyValue(
      (
        'values': $desktop,
        'propertyName': 'width',
        'gutters': 1,
        'gaps': 0,
        'columns': 3,
      )
    );

    @include setPropertyValue(
      (
        'values': $desktop,
        'propertyName': 'margin-right',
        'gutters': 0,
        'gaps': 1,
        'columns': 0,
      )
    );
  }
}

.productInfos {
  display: flex;
  flex-direction: row;

  .productInfosRows {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  .productTopRow {
    display: flex;
    flex-direction: column;
    width: 100%;

    .price {
      margin-bottom: theme($spacings, 4);
      white-space: nowrap;
    }

    .itemDesigner {
      margin-bottom: theme($spacings, 2);
    }

    @include from('md') {
      flex-direction: row;

      .price {
        order: 2;
        padding-left: theme($spacings, 10);
        margin-left: auto;
      }

      .productLink {
        order: 1;
      }
    }
  }

  .productInfo {
    display: flex;
    flex-direction: column;

    .label {
      display: inline-flex;
      align-items: center;
      margin-right: theme($spacings, 5);

      & + .sizeLabel {
        margin-top: theme($spacings, 10);
      }
    }

    .selectedSize {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: theme($spacings, 30);
      border-radius: theme($spacings, 15);
      padding: theme($spacings, 6);
      box-sizing: border-box;
      white-space: nowrap;
      color: theme($colors, 'black');
      border: 1px solid theme($colors, 'black');
      margin-left: theme($spacings, 6);
      margin-top: -#{theme($spacings, 6)};
      margin-bottom: -#{theme($spacings, 6)};
    }
  }

  .productBottomRow {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-top: theme($spacings, 30);

    .removeCta {
      padding-top: theme($spacings, 5);
      margin-top: auto;
    }

    @include from('lg') {
      margin-top: theme($spacings, 24);
    }
  }

  .deleteConfirmation {
    display: flex;
    flex-direction: column;
    margin-top: theme($spacings, 25);
    width: 100%;

    .deleteTitle {
      margin-bottom: theme($spacings, 5);
    }

    .deleteButtons {
      display: flex;

      .cta {
        &.hasInteracted {
          transform: scale(0.999);
        }

        flex: 1;

        & + .cta {
          margin-left: theme($spacings, 2);
        }
      }
    }
  }
}
