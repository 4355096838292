@import 'styles/theme';

.quickBuy {
  display: none;
  z-index: 4;
  width: 100%;
  background-color: theme($colors, 'white');

  &.isLoading {
    background-color: transparent;
  }

  @include from('md') {
    display: block;
  }

  .button {
    width: 100%;
    border: 1px solid theme($colors, 'black');

    &.disabled {
      opacity: 0.35;
      cursor: pointer;
    }

    &.addToCart {
      pointer-events: all;
      box-sizing: border-box;
    }
  }

  .loaderWrapper {
    width: 100%;
    text-align: center;

    .spinner {
      width: theme($spacings, 20);
    }
  }

  .variantsCta {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: theme($spacings, 6);
    padding: theme($spacings, 10);
    cursor: default;
    box-sizing: border-box;

    .variantQuickBuy {
      border-radius: theme($spacings, 15);
      min-width: theme($spacings, 30);
      height: theme($spacings, 30);
      padding: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 theme($spacings, 7);

      .spinner {
        width: theme($spacings, 10);
      }
    }
  }
}

.mobileButton {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: theme($spacings, 5);
  z-index: 3;

  &.fullWidth {
    width: 100%;
    position: static;
    transform: none;
  }

  .button {
    width: 100%;

    &.disabled {
      opacity: 0.35;
      cursor: pointer;
    }
  }

  &.displayBlock {
    position: static;
    transform: none;
    width: 100%;
    .button {
      width: 100%;
    }
  }

  @include from('md') {
    display: none;
  }
}

.icon {
  width: 10px;
  height: 10px;
  padding: 6px;
  border-radius: 50%;
  background-color: theme($colors, 'white');
}
