@import 'styles/theme';

.ProductSlider {
  position: relative;

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    padding: theme($mobile, 'gutter');

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }

  .sliderControls {
    position: absolute;
    bottom: theme($mobile, 'gutter');
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);

    .sliderButton {
      width: 4px;
      height: 4px;
      box-sizing: border-box;
      background: transparent;
      border: 1px solid theme($colors, 'gray');

      &.isActive {
        background: theme($colors, 'black');
        border-color: theme($colors, 'black');
      }
    }
  }
}
