@import 'styles/theme';

.PushTypeListingItem {
  color: theme($colors, 'scorpion');
  height: theme($spacings, 30);
  display: flex;
  align-items: center;

  @include from('lg') {
    height: unset;
  }

  &:hover {
    color: theme($colors, 'black');
  }
}

.separator {
  color: theme($colors, 'scorpion');
  margin-left: theme($spacings, 10);
  margin-right: theme($spacings, 10);
  display: flex;
  align-items: center;
}
