@import 'styles/theme';

.ProductTag {
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: theme($spacings, 3) theme($spacings, 5);
  border-radius: theme($spacings, 20);
  text-align: center;
}
