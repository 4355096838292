@import 'styles/theme';

.ProductCardTags {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: theme($spacings, 12);
  gap: theme($spacings, 5);

  .statusTags,
  .sustainabilityTags {
    display: flex;
    flex-direction: column;
    z-index: 2;
  }

  .statusTags {
    align-items: flex-start;
    flex: 1 1;
  }

  .sustainabilityTags {
    align-items: flex-end;
    flex: 1 1;
  }

  .tag {
    line-height: 1.1;
    font-size: theme($fontSizes, 8);
    @include from('md') {
      line-height: 1.3;
    }

    & + .tag {
      margin-top: theme($spacings, 6);
    }
  }

  @container productCard (min-width: 160px) {
    .tag {
      font-size: theme($fontSizes, 10);
    }
  }

  @container productCard (min-width: 250px) {
    .tag {
      font-size: theme($fontSizes, 12);
    }
  }
}
