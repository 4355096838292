@import 'styles/theme';

.CartToast {
  width: 100%;

  .toastTop {
    padding: theme($spacings, 20);
  }

  .cartItem {
    box-sizing: border-box;
    width: 100%;
    padding-right: theme($spacings, 20);
  }

  .toastBottom {
    display: flex;
    margin-top: theme($spacings, 30);

    .cta {
      flex: 1;
    }
  }
}
