@import 'styles/theme';

.SliderControls {
  display: flex;

  .buttonsWrapper {
    display: flex;
  }

  .button {
    cursor: pointer;
    width: theme($spacings, 5);
    height: theme($spacings, 5);
    background-color: transparent;
    transition: ease 0.2s background-color, ease 0.2s border-color;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: theme($spacings, 15);
    }
  }

  &.hasSmallerControls {
    .button {
      width: theme($spacings, 2);
      height: theme($spacings, 2);

      &:not(:last-child) {
        margin-right: theme($spacings, 5);
      }
    }
  }
}
