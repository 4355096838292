@use 'sass:math';
@import 'styles/theme';

.ProductCard {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative;

  @include setContainerContext(productCard, inline-size);

  .imageRatio {
    @include applyPercentRatios(math.div(3, 4));
    position: relative;
    overflow: hidden;
  }

  .imageContainer {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    width: 100%;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
    }

    .productSlider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .slider {
        pointer-events: none;
        width: 100%;

        .sliderItem {
          width: 100%;

          &.big_grid,
          &.regular,
          &.small_grid {
            @include applyColumnWidth(
              'width',
              theme($mobile, 'columns'),
              $mobile
            );
          }

          @media screen and (min-width: 768px) and (max-width: 930px) {
            &.big_grid,
            &.regular,
            &.small_grid {
              @include applyColumnWidth(
                'width',
                calc(theme($mobile, 'columns') / 3),
                $mobile
              );
            }
          }

          &.medium_grid,
          &.search_grid {
            @include applyColumnWidth(
              'width',
              calc(theme($mobile, 'columns') / 2),
              $mobile
            );
          }

          &.wishListCardMobile {
            @include applyColumnWidth(
              'width',
              calc(theme($mobile, 'columns') / 2),
              $mobile
            );
          }
        }
      }

      .sliderControls {
        bottom: theme($spacings, 50);
      }
    }

    .wishlistWrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .widthlistDetails {
        position: relative;

        .details,
        .wishlist {
          position: absolute;
          z-index: 2;
        }

        .details {
          display: flex;
          flex-wrap: wrap;
          gap: theme($spacings, 4);
          left: theme($spacings, 10);
          bottom: theme($spacings, 10);

          .tag {
            padding: theme($spacings, 3) theme($spacings, 8);
          }
        }

        .wishlist {
          top: 0;
          right: 0;
          width: theme($spacings, 40);
          height: theme($spacings, 40);
          z-index: 3;

          &.inList {
            top: auto;
            bottom: 0;
          }
        }
      }
    }

    .quickBuy {
      display: none;
    }

    @include from('md') {
      &:hover {
        .quickBuy {
          display: block;
        }
      }
    }

    .quickBuy {
      z-index: 10;
    }

    .hoverImage,
    .image {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .bottom {
    display: flex;
    margin-top: theme($spacings, 10);

    .bottomLeft,
    .price {
      display: flex;
      flex-direction: column;
    }

    .bottomLeft {
      width: 70%;

      .creator {
        margin-bottom: theme($spacings, 3);
      }
    }

    .price {
      align-items: flex-end;
      width: 30%;
      text-align: right;
    }
  }

  @include switchDesktopMobileClasses('l', flex);
}
