@use 'sass:math';
@import 'styles/theme';

.ImageWithLabel {
  width: 100%;
  display: flex;
  flex-direction: column;

  .image + .label {
    margin-top: theme($spacings, 10);
  }

  .ratio {
    @include applyPercentRatios(math.div(460, 650));
  }
}
