@import 'styles/theme';

.PushTypeListing {
  background: theme($colors, 'concrete');
  padding-top: theme($spacings, 40);
  padding-bottom: theme($spacings, 40);
  overflow: hidden;

  @include from('md') {
    display: flex;
    position: relative;
    box-sizing: border-box;
    padding-top: theme($spacings, 80);
    padding-bottom: theme($spacings, 80);
  }

  .imageWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
    @include applyColumnWidth('width', 10, $desktop);
    display: none;

    @include from('md') {
      display: block;
    }
  }

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .wrapper {
    @include from('md') {
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .typeItems,
  .ctaWrapper {
    grid-column: 1/-1;

    @include from('md') {
      grid-column: 4/-4;
    }
  }

  .typeItems {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  .ctaWrapper {
    text-align: center;
    margin-top: theme($spacings, 15);

    @include from('md') {
      margin-top: theme($spacings, 20);
    }
  }
}
