@import 'styles/theme';

.VideoPlayer {
  position: relative;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  position: absolute;
  bottom: theme($spacings, 10);
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
}

.progressBar {
  display: none;
}
