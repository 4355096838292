@import 'styles/theme';

.HeroVideo {
  @include toggleStableVh(100, 'md', 'height');
  position: relative;

  .video {
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }
}
