@import 'styles/theme';

.WishlistIcon {
  @media (hover) {
    &:hover {
      path {
        fill: theme($colors, 'black');
      }
    }
  }

  &.hasInteracted {
    transform: scale(0.99);
  }

  .icon.isActive {
    path {
      fill: theme($colors, 'black');
    }
  }
}
